<template>
  <div
    id="pricing-page"
  >
    <section id="new-pricing">
      <div class="container pt-4 pt-md-8 pb-10">
        <div
          class="v-responsive mx-auto text-center"
          style="max-width: 700px;"
        >
          <div class="v-responsive__content">
            <h2
              class="text-h3 text-lg-h2"
              v-text="$t('Pages.Pricing.pricing_title')"
            />
            <div
              class="text-h4 grey-text lighten-2 mt-2"
              v-text="$t('Pages.Pricing.pricing_subtitle')"
            />
          </div>
        </div>
        <v-row
          class="mt-10 align-center justify-center"
          dense
        >
          <v-col
            cols="12"
            md="6"
            lg="3"
          >
            <vue-aos animation-class="animate__animated animate__fadeIn animate__delay-250ms">
              <v-card
                outlined
                class="rounded-xl elevation-2 bleuDarken rounded-xl"
              >
                <v-sheet
                  id="upper-sheet"
                  class="pa-4 text-center align-center justify-center neutral"
                >
                  <div class="mb-6">
                    <div class="text-xs text-uppercase font-weight-bold">
                      {{ $t('Pages.Pricing.try_now') }}
                    </div>
                  </div>
                  <div class="d-flex justify-center">
                    <div class="text-h1">
                      0
                    </div>
                  </div>
                  <div
                    class="justify-end text-overline text--secondary text--darken-1"
                    v-text="$t('Pages.Pricing.sek_month_basic')"
                  />
                </v-sheet>
                <v-divider />
                <div class="pa-4">
                  <div class="my-6 text-h6">
                    <div class="d-flex align-center my-2">
                      <v-icon
                        color="secondary"
                        left
                      >
                        mdi-check
                      </v-icon>
                      <span>{{ $t('Pages.Pricing.search') }}</span>
                    </div>
                    <div class="d-flex align-center my-2">
                      <v-icon
                        color="secondary"
                        left
                      >
                        mdi-check
                      </v-icon>
                      <span>{{ $t('Pages.Pricing.anonymous_profiles') }}</span>
                    </div>
                  </div>
                  <v-btn
                    light
                    rounded
                    block
                    large
                    @click="onTryCtaClick()"
                    v-text="$t('Pages.Pricing.try_now')"
                  />
                </div>
              </v-card>
            </vue-aos>
          </v-col>
          <v-col
            v-if="true"
            cols="12"
            md="6"
            lg="3"
          >
            <vue-aos animation-class="animate__animated animate__fadeIn animate__delay-750ms">
              <v-card
                outlined
                class="text-body-1 v-card rounded-xl v-sheet v-sheet--outlined neutral elevation-2"
              >
                <div class="pa-4 text-center v-sheet neutral">
                  <div class="mb-6">
                    <div class="text-xs text-uppercase font-weight-bold">
                      {{ $t('Pages.Pricing.search_consultant') }}
                    </div>
                  </div>
                  <div>
                    <div class="d-flex justify-center">
                      <div class="text-h1">
                        499
                      </div>
                    </div>
                    <div
                      class="justify-end text-overline text--secondary text--darken-1"
                      v-text="$t('Pages.Pricing.sek_month')"
                    />
                  </div>
                </div>
                <!--
                <div style="position: absolute; left: 50%; top: 11.7rem;">
                  <v-chip
                    style="position: relative; left: -50%;"
                    light
                    class="white mb-n4"
                    v-text="$t('Pages.Pricing.popular')"
                  >
                    Popular
                  </v-chip>
                </div>
                -->
                <v-divider />
                <div class="pa-4 v-sheet bleuDarken surface">
                  <div class="my-6 text-h6">
                    <div class="d-flex align-center my-2">
                      <v-icon
                        color="secondary"
                        left
                      >
                        mdi-check
                      </v-icon>
                      <span>{{ $t('Pages.Pricing.search') }}</span>
                    </div>
                  </div>
                  <div class="my-6 text-h6">
                    <div class="d-flex align-center my-2">
                      <v-icon
                        color="secondary"
                        left
                      >
                        mdi-check
                      </v-icon>
                      <span>{{ $t('Pages.Pricing.contact_consultants') }}</span>
                    </div>
                  </div>
                  <div class="my-6 text-h6">
                    <div class="d-flex align-center my-2">
                      <v-icon
                        color="secondary"
                        left
                      >
                        mdi-check
                      </v-icon>
                      <span>{{ $t('Pages.Pricing.complete_profiles') }}</span>
                    </div>
                  </div>
                  <div class="my-6 text-h6">
                    <div class="d-flex align-center my-2">
                      <v-icon
                        color="secondary"
                        left
                      >
                        mdi-check
                      </v-icon>
                      <span>{{ $t('Pages.Pricing.secure_login') }}</span>
                    </div>
                  </div>
                  <v-btn
                    light
                    rounded
                    block
                    large
                    @click="onProductsCardClick()"
                    v-text="$t('Pages.Pricing.premium_plus_cta')"
                  />
                </div>
              </v-card>
            </vue-aos>
          </v-col>
          <v-col
            v-if="true"
            cols="12"
            md="6"
            lg="3"
          >
            <vue-aos animation-class="animate__animated animate__fadeIn animate__delay-750ms">
              <v-card
                outlined
                class="text-body-1 v-card rounded-xl v-sheet v-sheet--outlined neutral elevation-2"
              >
                <div class="pa-4 text-center v-sheet neutral">
                  <div class="mb-6">
                    <div class="text-xs text-uppercase font-weight-bold">
                      {{ $t('Pages.Pricing.consultant') }}
                    </div>
                  </div>
                  <div>
                    <div class="d-flex justify-center">
                      <div class="text-h1">
                        0
                      </div>
                    </div>
                    <div
                      class="justify-end text-overline text--secondary text--darken-1"
                      v-text="$t('Pages.Pricing.sek_month')"
                    />
                  </div>
                </div>
                <!--
                <div style="position: absolute; left: 50%; top: 11.7rem;">
                  <v-chip
                    style="position: relative; left: -50%;"
                    light
                    class="white mb-n4"
                    v-text="$t('Pages.Pricing.popular')"
                  >
                    Popular
                  </v-chip>
                </div>
                -->
                <v-divider />
                <div class="pa-4 v-sheet bleuDarken surface">
                  <div class="my-6 text-h6">
                    <div class="d-flex align-center my-2">
                      <v-icon
                        color="secondary"
                        left
                      >
                        mdi-check
                      </v-icon>
                      <span>{{ $t('Pages.Pricing.always_free') }}</span>
                    </div>
                  </div>
                  <div class="my-6 text-h6">
                    <div class="d-flex align-center my-2">
                      <v-icon
                        color="secondary"
                        left
                      >
                        mdi-check
                      </v-icon>
                      <span>{{ $t('Pages.Pricing.searchable') }}</span>
                    </div>
                  </div>
                  <div class="my-6 text-h6">
                    <div class="d-flex align-center my-2">
                      <v-icon
                        color="secondary"
                        left
                      >
                        mdi-check
                      </v-icon>
                      <span>{{ $t('Pages.Pricing.offers') }}</span>
                    </div>
                  </div>
                  <div class="my-6 text-h6">
                    <div class="d-flex align-center my-2">
                      <v-icon
                        color="secondary"
                        left
                      >
                        mdi-check
                      </v-icon>
                      <span>{{ $t('Pages.Pricing.secure_login') }}</span>
                    </div>
                  </div>
                  <v-btn
                    outlined
                    rounded
                    :to="{ name: 'dashboard' }"
                    color="secondary"
                    block
                    disabled
                    large
                    v-text="$t('Pages.Pricing.standard_cta')"
                  />
                </div>
              </v-card>
            </vue-aos>
          </v-col>
        </v-row>
      </div>
    </section>
  </div>
</template>

<script>
import VueAos from 'vue-aos'
import { mapState } from 'vuex'

export default {
  name: 'Pricing',
  components: { VueAos },
  metaInfo () {
    return {
      title: this.brandName
    }
  },
  computed: {
    ...mapState([
      'brandName',
      'products'
    ])
  },
  methods: {
    // TODO: Move to product page and pass in the priceId, rename to onProductClick
    onProductsCardClick (priceId) {
      // TODO: Refactor to use a more decisive way to find the "Sök & kontakta" product
      // Find product that contains string "kontakta" in the name
      const product = this.products.find(product => product.name.toLowerCase().includes('kontakta'))
      const payload = {
        priceId: product.priceId // 'price_1H8Z2pJZ2Z2Z2Z2Z2Z2Z2Z2Z'
      }
      this.$http.post('checkout/session', payload).then(res => {
        window.location.href = res.data.url
      }, err => {
        console.error('err', err)
        this.$root.$emit('errorMessage', err.response)
      })
    },
    onTryCtaClick () {
      // Send user to search
      this.$router.push({ name: 'inner-search' })
    }
  }
}
</script>
